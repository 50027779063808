import { createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "toolbar",
  id: "kt_toolbar"
}
const _hoisted_2 = {
  "data-kt-swapper": "true",
  "data-kt-swapper-mode": "prepend",
  "data-kt-swapper-parent": "{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}",
  class: "page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0 mx-10"
}
const _hoisted_3 = { class: "d-flex align-items-center text-dark my-1 fs-4" }
const _hoisted_4 = {
  key: 0,
  class: "h-20px border-gray-200 border-start mx-4"
}
const _hoisted_5 = {
  key: 1,
  class: "breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1"
}
const _hoisted_6 = { class: "breadcrumb-item pe-3" }
const _hoisted_7 = { class: "breadcrumb-item text-muted" }
const _hoisted_8 = { class: "breadcrumb-item pe-3 text-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Toolbar"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Container"),
      _createElementVNode("div", {
        id: "kt_toolbar_container",
        class: _normalizeClass([{
        'container-fluid': _ctx.toolbarWidthFluid,
        'container-xxl': !_ctx.toolbarWidthFluid,
      }, "d-flex flex-stack"])
      }, [
        _createCommentVNode("begin::Page title"),
        _createElementVNode("div", _hoisted_2, [
          _createCommentVNode("begin::Title"),
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.title), 1 /* TEXT */),
          _createCommentVNode("end::Title"),
          (_ctx.breadcrumbs)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4))
            : _createCommentVNode("v-if", true),
          _createCommentVNode("begin::Breadcrumb"),
          (_ctx.breadcrumbs)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                _createElementVNode("li", _hoisted_6, [
                  _createVNode(_component_router_link, {
                    to: "/dashboard",
                    class: "text-muted text-hover-primary text-decoration-none"
                  }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode(" Home ")
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("li", { class: "breadcrumb-item" }, [
                  _createElementVNode("span", { class: "bullet bg-gray-200 w-5px h-2px" })
                ], -1 /* HOISTED */)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    _createElementVNode("li", _hoisted_7, _toDisplayString(item), 1 /* TEXT */),
                    _cache[1] || (_cache[1] = _createElementVNode("li", { class: "breadcrumb-item" }, [
                      _createElementVNode("span", { class: "bullet bg-gray-200 w-5px h-2px" })
                    ], -1 /* HOISTED */))
                  ], 64 /* STABLE_FRAGMENT */))
                }), 128 /* KEYED_FRAGMENT */)),
                _createElementVNode("li", _hoisted_8, _toDisplayString(_ctx.title), 1 /* TEXT */)
              ]))
            : _createCommentVNode("v-if", true),
          _createCommentVNode("end::Breadcrumb")
        ]),
        _createCommentVNode("end::Page title")
      ], 2 /* CLASS */),
      _createCommentVNode("end::Container")
    ]),
    _createCommentVNode("end::Toolbar")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}