import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-start mb-10" }
const _hoisted_2 = { class: "d-flex flex-column align-items-start" }
const _hoisted_3 = { class: "d-flex align-items-center mb-2" }
const _hoisted_4 = { class: "symbol symbol-35px symbol-circle" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "ms-3" }
const _hoisted_7 = {
  href: "#",
  class: "fs-5 fw-bolder text-gray-900 text-hover-primary me-1"
}
const _hoisted_8 = { class: "text-muted fs-7 mb-1" }
const _hoisted_9 = {
  class: "p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start",
  "data-kt-element": "message-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Wrapper"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::User"),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              alt: "Pic",
              src: _ctx.image
            }, null, 8 /* PROPS */, _hoisted_5)
          ]),
          _createCommentVNode("begin::Details"),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.name), 1 /* TEXT */),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.time), 1 /* TEXT */)
          ]),
          _createCommentVNode("end::Details")
        ]),
        _createCommentVNode("end::User"),
        _createCommentVNode("begin::Text"),
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.text), 1 /* TEXT */),
        _createCommentVNode("end::Text")
      ])
    ]),
    _createCommentVNode("end::Wrapper")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}