import { createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  id: "kt_aside_menu_wrapper",
  ref: "scrollElRef",
  class: "hover-scroll-overlay-y my-5 my-lg-5",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-dependencies": "#kt_aside_logo, #kt_aside_footer",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-offset": "0",
  "data-kt-scroll-wrappers": "#kt_aside_menu"
}
const _hoisted_2 = {
  id: "#kt_header_menu",
  class: "menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500",
  "data-kt-menu": "true"
}
const _hoisted_3 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_4 = { class: "menu-content pb-2" }
const _hoisted_5 = { class: "menu-section text-muted text-uppercase fs-7 ls-1" }
const _hoisted_6 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_7 = {
  key: 0,
  class: "menu-icon"
}
const _hoisted_8 = {
  key: 1,
  class: "svg-icon svg-icon-2"
}
const _hoisted_9 = { class: "menu-title subtitle" }
const _hoisted_10 = { class: "menu-link hover-menu" }
const _hoisted_11 = {
  key: 0,
  class: "menu-icon"
}
const _hoisted_12 = {
  key: 1,
  class: "svg-icon svg-icon-2"
}
const _hoisted_13 = { class: "menu-title" }
const _hoisted_14 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_15 = { class: "menu-title" }
const _hoisted_16 = { class: "menu-link" }
const _hoisted_17 = { class: "menu-title" }
const _hoisted_18 = {
  key: 0,
  class: "menu-item"
}
const _hoisted_19 = { class: "menu-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Menu wrapper"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Menu"),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.head, (item, i) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
            (item.heading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.translate(item.heading)), 1 /* TEXT */)
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.devices, (menuItem, j) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: j }, [
                (menuItem.heading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_router_link, {
                        class: "menu-link hover-menu text-decoration-none",
                        "active-class": "active",
                        to: menuItem.route
                      }, {
                        default: _withCtx(() => [
                          (menuItem.svgIcon || menuItem.fontIcon)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
                                (_ctx.asideMenuIcons === 'font')
                                  ? (_openBlock(), _createElementBlock("i", {
                                      key: 0,
                                      class: _normalizeClass([menuItem.fontIcon, "bi fs-3"])
                                    }, null, 2 /* CLASS */))
                                  : (_ctx.asideMenuIcons === 'svg')
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                                        _createVNode(_component_inline_svg, {
                                          src: menuItem.svgIcon
                                        }, null, 8 /* PROPS */, ["src"])
                                      ]))
                                    : _createCommentVNode("v-if", true)
                              ]))
                            : _createCommentVNode("v-if", true),
                          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.translate(menuItem.heading)), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
                    ]))
                  : _createCommentVNode("v-if", true),
                (menuItem.sectionTitle)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass([{ show: _ctx.hasActiveChildren(menuItem.route) }, "menu-item menu-accordion"]),
                      "data-kt-menu-sub": "accordion",
                      "data-kt-menu-trigger": "click"
                    }, [
                      _createElementVNode("span", _hoisted_10, [
                        (menuItem.svgIcon || menuItem.fontIcon)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, [
                              (_ctx.asideMenuIcons === 'font')
                                ? (_openBlock(), _createElementBlock("i", {
                                    key: 0,
                                    class: _normalizeClass([menuItem.fontIcon, "bi fs-3"])
                                  }, null, 2 /* CLASS */))
                                : (_ctx.asideMenuIcons === 'svg')
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                      _createVNode(_component_inline_svg, {
                                        src: menuItem.svgIcon
                                      }, null, 8 /* PROPS */, ["src"])
                                    ]))
                                  : _createCommentVNode("v-if", true)
                            ]))
                          : _createCommentVNode("v-if", true),
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.translate(menuItem.sectionTitle)), 1 /* TEXT */),
                        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "menu-arrow" }, null, -1 /* HOISTED */))
                      ]),
                      _createElementVNode("div", {
                        class: _normalizeClass([{ show: _ctx.hasActiveChildren(menuItem.route) }, "menu-sub menu-sub-accordion"])
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuItem.sub, (item2, k) => {
                          return (_openBlock(), _createElementBlock(_Fragment, { key: k }, [
                            (item2.heading)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                  _createVNode(_component_router_link, {
                                    class: "menu-link",
                                    "active-class": "active",
                                    to: item2.route
                                  }, {
                                    default: _withCtx(() => [
                                      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "menu-bullet" }, [
                                        _createElementVNode("span", { class: "bullet bullet-dot" })
                                      ], -1 /* HOISTED */)),
                                      _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.translate(item2.heading)), 1 /* TEXT */)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
                                ]))
                              : _createCommentVNode("v-if", true),
                            (item2.sectionTitle)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 1,
                                  class: _normalizeClass([{ show: _ctx.hasActiveChildren(item2.route) }, "menu-item menu-accordion"]),
                                  "data-kt-menu-sub": "accordion",
                                  "data-kt-menu-trigger": "click"
                                }, [
                                  _createElementVNode("span", _hoisted_16, [
                                    _cache[2] || (_cache[2] = _createElementVNode("span", { class: "menu-bullet" }, [
                                      _createElementVNode("span", { class: "bullet bullet-dot" })
                                    ], -1 /* HOISTED */)),
                                    _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.translate(item2.sectionTitle)), 1 /* TEXT */),
                                    _cache[3] || (_cache[3] = _createElementVNode("span", { class: "menu-arrow" }, null, -1 /* HOISTED */))
                                  ]),
                                  _createElementVNode("div", {
                                    class: _normalizeClass([{ show: _ctx.hasActiveChildren(item2.route) }, "menu-sub menu-sub-accordion"])
                                  }, [
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item2.sub, (item3, k) => {
                                      return (_openBlock(), _createElementBlock(_Fragment, { key: k }, [
                                        (item3.heading)
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                                              _createVNode(_component_router_link, {
                                                class: "menu-link",
                                                "active-class": "active",
                                                to: item3.route
                                              }, {
                                                default: _withCtx(() => [
                                                  _cache[4] || (_cache[4] = _createElementVNode("span", { class: "menu-bullet" }, [
                                                    _createElementVNode("span", { class: "bullet bullet-dot" })
                                                  ], -1 /* HOISTED */)),
                                                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.translate(item3.heading)), 1 /* TEXT */)
                                                ]),
                                                _: 2 /* DYNAMIC */
                                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
                                            ]))
                                          : _createCommentVNode("v-if", true)
                                      ], 64 /* STABLE_FRAGMENT */))
                                    }), 128 /* KEYED_FRAGMENT */))
                                  ], 2 /* CLASS */)
                                ], 2 /* CLASS */))
                              : _createCommentVNode("v-if", true)
                          ], 64 /* STABLE_FRAGMENT */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ], 2 /* CLASS */)
                    ], 2 /* CLASS */))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            }), 128 /* KEYED_FRAGMENT */))
          ], 64 /* STABLE_FRAGMENT */))
        }), 128 /* KEYED_FRAGMENT */)),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "menu-item" }, [
          _createElementVNode("div", { class: "menu-content" }, [
            _createElementVNode("div", { class: "separator mx-1 my-4" })
          ])
        ], -1 /* HOISTED */)),
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "menu-item" }, null, -1 /* HOISTED */))
      ]),
      _createCommentVNode("end::Menu")
    ], 512 /* NEED_PATCH */),
    _createCommentVNode("end::Menu wrapper")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}