import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "footer py-4 d-flex flex-lg-column",
  id: "kt_footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Footer"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Container"),
      _createElementVNode("div", {
        class: _normalizeClass(["d-flex flex-column flex-md-row align-items-center justify-content-between", {
        'container-fluid': _ctx.footerWidthFluid,
        'container-xxl': !_ctx.footerWidthFluid,
      }])
      }, [
        _createCommentVNode("begin::Copyright"),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "text-dark order-2 order-md-1" }, [
          _createElementVNode("span", { class: "text-muted fw-bold me-1" }, [
            _createTextVNode(" 2022© All rights reserved by "),
            _createElementVNode("a", {
              href: "https://www.smartends.com/",
              target: "_blank"
            }, "SmartEnds.")
          ])
        ], -1 /* HOISTED */)),
        _createCommentVNode("end::Copyright"),
        _createCommentVNode("version  ")
      ], 2 /* CLASS */),
      _createCommentVNode("end::Container")
    ]),
    _createCommentVNode("end::Footer")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}