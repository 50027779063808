import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Menu wrapper"),
    _cache[0] || (_cache[0] = _createElementVNode("div", {
      class: "header-menu align-items-stretch",
      "data-kt-drawer": "true",
      "data-kt-drawer-name": "header-menu",
      "data-kt-drawer-activate": "{default: true, lg: false}",
      "data-kt-drawer-overlay": "true",
      "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
      "data-kt-drawer-direction": "end",
      "data-kt-drawer-toggle": "#kt_header_menu_mobile_toggle",
      "data-kt-place": "true",
      "data-kt-place-mode": "prepend",
      "data-kt-place-parent": "{default: '#kt_body', lg: '#kt_header_nav'}"
    }, [
      _createCommentVNode("begin::Menu"),
      _createElementVNode("div", {
        class: "menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch mx-6",
        id: "#kt_header_menu",
        "data-kt-menu": "true"
      })
    ], -1 /* HOISTED */)),
    _createCommentVNode("end::Menu wrapper")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}