import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "aside-logo flex-column-auto",
  id: "kt_aside_logo"
}
const _hoisted_2 = {
  key: 0,
  href: "#"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  href: "#"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  id: "kt_aside_toggle",
  class: "btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle",
  "data-kt-toggle": "true",
  "data-kt-toggle-state": "active",
  "data-kt-toggle-target": "body",
  "data-kt-toggle-name": "aside-minimize"
}
const _hoisted_7 = { class: "svg-icon svg-icon-1 rotate-180" }
const _hoisted_8 = { class: "aside-menu flex-column-fluid" }
const _hoisted_9 = {
  class: "aside-footer flex-column-auto pt-5 pb-7 px-5",
  id: "kt_aside_footer"
}
const _hoisted_10 = {
  style: {"display":"none"},
  class: "btn btn-custom btn-primary w-100",
  "data-bs-toggle": "tooltip",
  "data-bs-trigger": "hover",
  "data-bs-delay-show": "8000",
  title: "Check out the complete documentation with over 100 components"
}
const _hoisted_11 = { class: "svg-icon btn-icon svg-icon-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTMenu = _resolveComponent("KTMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Aside"),
    _createElementVNode("div", {
      id: "kt_aside",
      class: _normalizeClass(["aside aside-hoverable shadow", [
      _ctx.asideTheme === 'light' && 'aside-light',
      _ctx.asideTheme === 'dark' && 'aside-dark',
    ]]),
      "data-kt-drawer": "true",
      "data-kt-drawer-name": "aside",
      "data-kt-drawer-activate": "{default: true, lg: false}",
      "data-kt-drawer-overlay": "true",
      "data-kt-drawer-width": "{default:'200px', '300px': '250px'}",
      "data-kt-drawer-direction": "start",
      "data-kt-drawer-toggle": "#kt_aside_mobile_toggle"
    }, [
      _createCommentVNode("begin::Brand"),
      _createElementVNode("div", _hoisted_1, [
        _createCommentVNode("begin::Logo"),
        _createVNode(_component_router_link, {
          class: "menu-link",
          "active-class": "active",
          to: "/dashboard"
        }, {
          default: _withCtx(() => [
            (_ctx.asideTheme === 'dark')
              ? (_openBlock(), _createElementBlock("a", _hoisted_2, [
                  _createElementVNode("img", {
                    alt: "Logo",
                    src: _ctx.darkLogo,
                    class: "h-40px logo"
                  }, null, 8 /* PROPS */, _hoisted_3)
                ]))
              : _createCommentVNode("v-if", true),
            (_ctx.asideTheme === 'light')
              ? (_openBlock(), _createElementBlock("a", _hoisted_4, [
                  _createElementVNode("img", {
                    alt: "Logo",
                    src: _ctx.lightLogo,
                    class: "h-40px logo"
                  }, null, 8 /* PROPS */, _hoisted_5)
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        _createCommentVNode("end::Logo"),
        _createCommentVNode("begin::Aside toggler"),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("span", _hoisted_7, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr080.svg" })
          ])
        ]),
        _createCommentVNode("end::Aside toggler")
      ]),
      _createCommentVNode("end::Brand"),
      _createCommentVNode("begin::Aside menu"),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_KTMenu)
      ]),
      _createCommentVNode("end::Aside menu"),
      _createCommentVNode("begin::Footer"),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("a", _hoisted_10, [
          _createElementVNode("span", _hoisted_11, [
            _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen005.svg" })
          ])
        ])
      ]),
      _createCommentVNode("end::Footer")
    ], 2 /* CLASS */),
    _createCommentVNode("end::Aside")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}