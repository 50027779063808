import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "page-loader flex-column"
}
const _hoisted_2 = {
  key: 1,
  class: "page-loader flex-column"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  class: "page-loader"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Page loader"),
    (_ctx.loaderType === 'spinner-message')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("span", {
            class: "spinner-border text-primary",
            role: "status"
          }, null, -1 /* HOISTED */),
          _createElementVNode("span", { class: "text-muted fs-6 fw-bold mt-5" }, "Loading...", -1 /* HOISTED */)
        ])))
      : (_ctx.loaderType === 'spinner-logo')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              alt: "Logo",
              class: "max-h-75px",
              src: _ctx.logo
            }, null, 8 /* PROPS */, _hoisted_3),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "d-flex align-items-center mt-5" }, [
              _createElementVNode("span", {
                class: "spinner-border text-primary",
                role: "status"
              }),
              _createElementVNode("span", { class: "text-muted fs-6 fw-bold ms-5" }, "Loading...")
            ], -1 /* HOISTED */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _cache[2] || (_cache[2] = [
            _createElementVNode("span", {
              class: "spinner-border text-primary",
              role: "status"
            }, [
              _createElementVNode("span", { class: "visually-hidden" }, "Loading...")
            ], -1 /* HOISTED */)
          ]))),
    _createCommentVNode("end::Page Loader")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}