import { createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "kt_header",
  style: {},
  class: "header align-items-stretch"
}
const _hoisted_2 = {
  class: "d-flex align-items-center d-lg-none ms-n3 me-1",
  title: "Show aside menu"
}
const _hoisted_3 = {
  class: "btn btn-icon btn-active-light-primary",
  id: "kt_aside_mobile_toggle"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2x mt-1" }
const _hoisted_5 = { class: "d-flex align-items-stretch justify-content-between flex-lg-grow-1" }
const _hoisted_6 = {
  class: "d-flex align-items-stretch",
  id: "kt_header_menu_nav"
}
const _hoisted_7 = { class: "d-flex align-items-stretch flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTMenu = _resolveComponent("KTMenu")!
  const _component_KTTopbar = _resolveComponent("KTTopbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Header"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Container"),
      _createElementVNode("div", {
        class: _normalizeClass([{
        'container-fluid': _ctx.headerWidthFluid,
        'container-xxl': !_ctx.headerWidthFluid,
      }, "d-flex align-items-stretch justify-content-between"])
      }, [
        _createCommentVNode("begin::Aside mobile toggle"),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/abstract/abs015.svg" })
            ])
          ])
        ]),
        _createCommentVNode("end::Aside mobile toggle"),
        _createCommentVNode("begin::Mobile logo"),
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "d-flex align-items-center flex-grow-1 flex-lg-grow-0" }, [
          _createElementVNode("a", {
            href: "#",
            class: "d-lg-none"
          }, [
            _createElementVNode("img", {
              alt: "Logo",
              src: "/media/logos/bblogo2.png",
              class: "h-30px"
            })
          ])
        ], -1 /* HOISTED */)),
        _createCommentVNode("end::Mobile logo"),
        _createCommentVNode("begin::Wrapper"),
        _createElementVNode("div", _hoisted_5, [
          _createCommentVNode("begin::Navbar"),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_KTMenu)
          ]),
          _createCommentVNode("end::Navbar"),
          _createCommentVNode("begin::Topbar"),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_KTTopbar)
          ]),
          _createCommentVNode("end::Topbar")
        ]),
        _createCommentVNode("end::Wrapper")
      ], 2 /* CLASS */),
      _createCommentVNode("end::Container")
    ]),
    _createCommentVNode("end::Header")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}