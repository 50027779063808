import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "menu-item px-3" }
const _hoisted_3 = { class: "menu-content d-flex align-items-center px-3" }
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "fw-bolder d-flex align-items-center fs-5" }
const _hoisted_6 = {
  href: "#",
  class: "fw-bold text-muted text-hover-primary fs-7"
}
const _hoisted_7 = { class: "menu-item px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Menu"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::Menu item"),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Avatar"),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "symbol symbol-50px me-5" }, [
            _createElementVNode("img", {
              alt: "Logo",
              src: "/media/avatars/blank.png"
            })
          ], -1 /* HOISTED */)),
          _createCommentVNode("end::Avatar"),
          _createCommentVNode("begin::Username"),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.user.username), 1 /* TEXT */),
            _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.user.email), 1 /* TEXT */)
          ]),
          _createCommentVNode("end::Username")
        ])
      ]),
      _createCommentVNode("end::Menu item"),
      _createCommentVNode("begin::Menu separator"),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "separator my-2" }, null, -1 /* HOISTED */)),
      _createCommentVNode("end::Menu separator"),
      _createCommentVNode("begin::Menu item"),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.signOut())),
          class: "menu-link px-5"
        }, " Sign Out ")
      ]),
      _createCommentVNode("end::Menu item")
    ]),
    _createCommentVNode("end::Menu")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}