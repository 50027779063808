import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Toolbar wrapper"),
    _createElementVNode("div", _hoisted_1, [
      _createCommentVNode("begin::User"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Menu"),
        _cache[0] || (_cache[0] = _createElementVNode("div", {
          class: "cursor-pointer symbol symbol-30px symbol-md-40px",
          style: {"padding-right":"10px"},
          "data-kt-menu-trigger": "click",
          "data-kt-menu-attach": "parent",
          "data-kt-menu-placement": "bottom-end",
          "data-kt-menu-flip": "bottom"
        }, [
          _createElementVNode("img", {
            src: "/media/avatars/blank.png",
            alt: "metronic"
          })
        ], -1 /* HOISTED */)),
        _createVNode(_component_KTUserMenu),
        _createCommentVNode("end::Menu")
      ]),
      _createCommentVNode("end::User ")
    ]),
    _createCommentVNode("end::Toolbar wrapper")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}